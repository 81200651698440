:root {
    --main-blue: #063970;
    --main-blue-lighter: #074281;
    --main-white: white;
    --main-off-white: #06397007;
    --main-green: #13aa52;
    --main-red: rgb(139, 0, 0);
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Roboto Condensed', Helvetica, sans-serif;
}

.pointer {
    cursor: pointer;
}

.logo{ 
    width: 90px;
}