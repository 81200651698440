.qr_code_blank {
    width: 250px;
    height: 250px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
}




